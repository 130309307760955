export class CookieManager {
    /**
     * Add an entry to an array in a cookie, preventing duplicates
     * @param {Object} options - Options object
     *   @param {Object} options.config - Configuration object
     *     @param {Boolean} options.config.areEntriesUnique - Flag indicating whether entries should be unique
     *   @param {String} options.entry - The entry to be added to the array
     *   @param {Date|Null} [options.expires=null] - Optional expiration date that, if not provided, defaults to a session cookie
     *   @param {Number} options.maxArrayLength - Maximum allowed length of the array
     *   @param {Number} options.maxByteSize - Maximum byte size of the cookie
     *   @param {String} options.name - The cookie name where the array is stored
     *   @param {String} [options.path="/"] - The path for the cookie
     * @returns {Boolean} - Returns true if added successfully, false if not added
     */
    static addToCookieArray({
        config = { areEntriesUnique: false },
        entry,
        expires = null,
        maxArrayLength = 50,
        maxByteSize = 4096,
        name,
        path = "/",
    } = {}) {
        let array = this.getCookie(name);
        array = array ? JSON.parse(array) : [];

        // Prevent adding duplicates if entries should be unique
        if (config.areEntriesUnique && array.includes(entry)) {
            return false;
        }

        // Prevent exceeding max array length
        if (array.length >= maxArrayLength) {
            return false;
        }

        array.push(entry);
        const value = JSON.stringify(array);

        // Check if the new array exceeds max byte size
        const estimatedCookieSize = `${encodeURIComponent(
            name
        )}=${encodeURIComponent(value)}`.length;
        if (estimatedCookieSize > maxByteSize) {
            return false;
        }

        // Set the updated array in the cookie
        this.setCookie({
            expires,
            name,
            path,
            value,
        });
        return true;
    }

    /**
     * Clear a cookie by name
     * @param {String} name - The name of the cookie to be cleared
     */
    static clearCookie(name) {
        this.setCookie(name, "", new Date(0)); // Expire the cookie in the past
    }

    /**
     * Get a cookie by name
     * @param {String} name - The name of the cookie
     * @returns {String|Null} - The cookie value or null if not found
     */
    static getCookie(name) {
        const nameEncoded = `${encodeURIComponent(name)}=`;
        const cookies = document.cookie.split(";");
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.indexOf(nameEncoded) === 0) {
                return decodeURIComponent(
                    cookie.substring(nameEncoded.length, cookie.length)
                );
            }
        }
        return null;
    }

    /**
     * Set a cookie with an optional expiration date
     * @param {Object} options - Options object.
     *   @param {Date|Null} [options.expires=null] - Optional expiration date that, if not provided, defaults to a session cookie
     *   @param {String} options.name - The name of the cookie
     *   @param {String} [options.path="/"] - The path for the cookie
     *   @param {String} options.value - The value to be stored in the cookie
     */
    static setCookie({ expires = null, name, path = "/", value } = {}) {
        let cookieString = `${encodeURIComponent(name)}=${encodeURIComponent(
            value
        )}; path=${path}`;
        if (expires) {
            cookieString += `; expires=${expires.toUTCString()}`;
        }
        document.cookie = cookieString;
    }
}
