import React from "react";

// Components
import { SquadsFilledIcon } from "components/Icons/Navigation/SquadsIcon";
import { Text } from "components/Text/Text";

// CSS
import styles from "./support-squad-detail-empty.module.scss";

// Localization
import { useTranslation } from "localization/localization";

export const SupportSquadDetailEmpty = () => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation("support-squad");

    /**
     * End Hooks
     */

    return (
        <div className={styles.supportSquadDetailEmpty}>
            <SquadsFilledIcon
                className={styles.icon}
                color="var(--midnight-60)"
                width="64px"
            />
            <div className={styles.body}>
                <Text classes={styles.title} isBold>
                    {t("detail.empty_title", {
                        ns: "support_squad",
                    })}
                </Text>
                <Text classes={styles.subtitle}>
                    {t("detail.empty_subtitle", {
                        ns: "support_squad",
                    })}
                </Text>
            </div>
        </div>
    );
};
