import React, { useState } from "react";
import cx from "classnames";
import PropTypes from "prop-types";

// Components
import { ButtonInvisible, ButtonPrimary } from "components/Buttons/Buttons";
import { Modal } from "components/Modals/Modal";
import { Text } from "components/Text/Text";
import { WarningCircleIcon } from "components/Icons/WarningCircleIcon";

// CSS
import styles from "./support-squad-leave-modal.module.scss";

// Localization
import { useTranslation } from "localization/localization";

export const SupportSquadLeaveModal = ({
    leaveSquad,
    setShouldShowLeaveModal,
    shouldShowLeaveModal,
    selectedSquad,
}) => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation("support-squad");

    /**
     * useState
     */

    const [isLeavingSquad, setIsLeavingSquad] = useState(false);

    /**
     * End Hooks
     */

    function renderContent() {
        return (
            <div className={styles.content}>
                <WarningCircleIcon height="64" width="64" />
                <span className={styles.headerText}>
                    {t("leave.leave_title", { ns: "support_squad" })}
                </span>
                <span className={styles.bodyText}>
                    {t("leave.leave_subtitle", {
                        ns: "support_squad",
                        squadName: selectedSquad?.squadName,
                    })}
                </span>
            </div>
        );
    }

    function renderButtons() {
        return (
            <div className={styles.buttons}>
                <ButtonPrimary
                    classes={cx(
                        styles["remove-button"],
                        "button--primary-warning"
                    )}
                    cypressTestId="support-squad-leave-modal-confirm-button"
                    isDisabled={isLeavingSquad}
                    onClick={() => {
                        setIsLeavingSquad(true);
                        leaveSquad(() => setIsLeavingSquad(false));
                    }}
                    isLoading={isLeavingSquad}
                >
                    <Text>
                        {t("leave.leave_group", { ns: "support_squad" })}
                    </Text>
                </ButtonPrimary>
                <ButtonInvisible
                    classes={styles.button}
                    cypressTestId="support-squad-leave-modal-cancel-button"
                    onClick={() => setShouldShowLeaveModal(false)}
                >
                    <Text>{t("cancel", { ns: "common" })}</Text>
                </ButtonInvisible>
            </div>
        );
    }

    return (
        <Modal
            className={styles["support-squad-leave-modal"]}
            centered="true"
            cypressTestId="support-squad-leave-modal"
            onHide={() => setShouldShowLeaveModal(false)}
            show={shouldShowLeaveModal}
            shouldHideHeader={true}
        >
            {renderContent()}
            {renderButtons()}
        </Modal>
    );
};

SupportSquadLeaveModal.propTypes = {
    leaveSquad: PropTypes.func.isRequired,
    setShouldShowLeaveModal: PropTypes.func.isRequired,
    shouldShowLeaveModal: PropTypes.bool.isRequired,
    selectedSquad: PropTypes.object,
};
