import { useEffect } from "react";

// Localization
import { useTranslation } from "localization/localization";

export const useSupportSquadSettingsModalMembers = ({
    setHeaderSubText,
    setHeaderText,
}) => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * useEffect
     */

    useEffect(() => {
        setHeaderSubText("");
        setHeaderText(
            t("support_squad_member_plural", { ns: "support_squad" })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * End Hooks
     */
};
