import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";

// Components
import { ButtonInvisible, ButtonPrimary } from "components/Buttons/Buttons";
import { Text } from "components/Text/Text";
import { WarningCircleIcon } from "components/Icons/WarningCircleIcon";

// CSS
import styles from "./support-squad-delete-modal.module.scss";

// Localization
import { useTranslation } from "localization/localization";

export const SupportSquadDeleteModalRemoveMemberWarning = (props) => {
    const { handleModalClose, navigateToManageSquad, selectedSquad } = props;

    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * End Hooks
     */

    function renderContent() {
        return (
            <div className={styles.container}>
                <WarningCircleIcon
                    className={styles.checkmarkSuccess}
                    height="64"
                    width="64"
                />
                <span className={styles.headerText}>
                    {t("settings.delete_warning_title", {
                        ns: "support_squad",
                    })}
                </span>
                <span className={styles.bodyText}>
                    {t("settings.delete_warning_subtitle", {
                        squadName: selectedSquad.squadName,
                        ns: "support_squad",
                    })}
                </span>
            </div>
        );
    }

    function renderButtons() {
        return (
            <>
                <ButtonPrimary
                    classes={cx(
                        styles["remove-button"],
                        "button--primary-warning"
                    )}
                    cypressTestId="support-squad-delete-modal-remove-member-warning-continue-button"
                    onClick={navigateToManageSquad}
                >
                    <Text>{t("remove_members", { ns: "common" })}</Text>
                </ButtonPrimary>
                <ButtonInvisible
                    classes={styles.button}
                    cypressTestId="support-squad-delete-modal-remove-member-warning-cancel-button"
                    onClick={handleModalClose}
                >
                    <Text>{t("cancel", { ns: "common" })}</Text>
                </ButtonInvisible>
            </>
        );
    }

    return (
        <div
            className={styles.removeMembersWarning}
            data-cy="support-squad-delete-modal-remove-member-warning"
        >
            <div className="modal__content">{renderContent()}</div>
            <div className={styles.buttonContainer}>{renderButtons()}</div>
        </div>
    );
};

SupportSquadDeleteModalRemoveMemberWarning.propTypes = {
    handleModalClose: PropTypes.func.isRequired,
    navigateToManageSquad: PropTypes.func.isRequired,
    selectedSquad: PropTypes.object.isRequired,
};
