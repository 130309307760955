import { useEffect, useRef } from "react";

// Localization
import { useTranslation } from "localization/localization";

export const useSupportSquadSettingsModalCreateName = ({
    setHeaderText,
    setHeaderSubText,
}) => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * useRef
     */

    const fileInputRef = useRef(null);

    /**
     * useEffect
     */

    useEffect(() => {
        setHeaderText(t("settings.create_title", { ns: "support_squad" }));
        setHeaderSubText(
            t("settings.create_subtitle", { ns: "support_squad" })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * End Hooks
     */

    function handleAddPhotoClick() {
        fileInputRef.current.click();
    }

    return { fileInputRef, handleAddPhotoClick };
};
