import React from "react";
import cx from "classnames";
import { useHistory } from "react-router";

// Components
import { ButtonPrimary } from "../../../components/Buttons/Buttons";
import { Panel } from "../../../components/Panel/Panel";
import { Text } from "components/Text/Text";

// CSS
import styles from "./dashboard-wallet.module.scss";

// Localization
import { useTranslation } from "localization/localization";

// Loggers
import { AnalyticsLogger } from "loggers/AnalyticsLogger";

// Constants
import giftcardImageSrc from "media/dashboard/giftcard.svg";

export const DashboardWallet = () => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * Router Hooks
     */

    const history = useHistory();

    /**
     * End Hooks
     */

    function handleGoToWallet() {
        AnalyticsLogger.logGiftCardViewed();
        history.push("/sandboxx-gift-card");
    }

    return (
        <Panel>
            <div className={styles["dashboard__wallet"]}>
                <img
                    alt={t("wallet.alt_gift_card", { ns: "dashboard" })}
                    className={styles.image}
                    src={giftcardImageSrc}
                />
                <span className={styles.text}>
                    {t("wallet.send_gift_card", { ns: "dashboard" })}
                </span>
                <ButtonPrimary
                    classes={cx(styles.button, "button--size-short")}
                    cypressTestId="dashboard-wallet-my-wallet-button"
                    onClick={handleGoToWallet}
                >
                    <Text>{t("gift_card_ip_plural", { ns: "common" })}</Text>
                </ButtonPrimary>
            </div>
        </Panel>
    );
};
