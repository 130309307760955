import React from "react";
import PropTypes from "prop-types";

// Components
import { ButtonInvisible, ButtonPrimary } from "components/Buttons/Buttons";
import { Modal } from "components/Modals/Modal";
import { Text } from "components/Text/Text";
import { WarningTriangleIcon } from "components/Icons/WarningTriangleIcon";

// CSS
import styles from "./support-squad-no-tokens-modal.module.scss";

// Localization
import { useTranslation } from "localization/localization";

export const SupportSquadNoTokensModal = ({
    setShouldShowDonateTokensModal,
    setShouldShowNoTokensModal,
    shouldShowNoTokensModal,
}) => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation("support-squad");

    /**
     * End Hooks
     */

    return (
        <Modal
            centered="true"
            className={styles["support-squad-no-tokens-modal"]}
            cypressTestId="no-tokens-modal"
            onHide={() => setShouldShowNoTokensModal(false)}
            shouldHideHeader={true}
            show={shouldShowNoTokensModal}
            size="sm"
        >
            <div className={styles.icon}>
                <WarningTriangleIcon color="#345168" height="42" width="42" />
            </div>
            <div className={styles.header}>
                <span>{t("no_tokens.title", { ns: "support_squad" })}</span>
            </div>
            <div className={styles.text}>
                <span>{t("no_tokens.subtitle", { ns: "support_squad" })}</span>
            </div>
            <div className={styles.buttons}>
                <ButtonPrimary
                    classes={styles.button}
                    cypressTestId="no-tokens-modal-donate-button"
                    onClick={() => {
                        setShouldShowNoTokensModal(false);
                        setShouldShowDonateTokensModal(true);
                    }}
                >
                    <Text>{t("donate_letters", { ns: "support_squad" })}</Text>
                </ButtonPrimary>
                <ButtonInvisible
                    classes={styles.button}
                    cypressTestId="no-tokens-modal-cancel-button"
                    onClick={() => setShouldShowNoTokensModal(false)}
                >
                    <Text>{t("cancel", { ns: "common" })}</Text>
                </ButtonInvisible>
            </div>
        </Modal>
    );
};

SupportSquadNoTokensModal.propTypes = {
    setShouldShowDonateTokensModal: PropTypes.func.isRequired,
    setShouldShowNoTokensModal: PropTypes.func.isRequired,
    shouldShowNoTokensModal: PropTypes.bool.isRequired,
};
