import { useEffect } from "react";

// Localization
import { useTranslation } from "localization/localization";

export const useSupportSquadSettingsModalCreateInvite = ({
    setHeaderText,
    setHeaderSubText,
    squadName,
    selectedSquad,
}) => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * useEffect
     */

    useEffect(() => {
        setHeaderText(
            t("settings.invite_title", {
                ns: "support_squad",
                squadName: selectedSquad.squadName || squadName,
            })
        );
        setHeaderText(
            t("settings.invite_subtitle", {
                ns: "support_squad",
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedSquad]);

    /**
     * End Hooks
     */
};
