import React from "react";
import PropTypes from "prop-types";

// Components
import { Banner } from "components/Banner/Banner";
import { Text } from "components/Text/Text";

// Enums
import { SquadRole } from "scenes/SupportSquad/enums/SquadRole";

// Localization
import { useTranslation } from "localization/localization";

export const SupportSquadDetailBanner = ({
    isFetchingSquad,
    setCurrentSection,
    setShouldShowCreateModal,
    selectedSquad,
}) => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * Constants
     */

    const isSquadAdmin = selectedSquad?.role === SquadRole.ADMIN;
    const shouldShowNoPhotoBanner =
        isSquadAdmin && !selectedSquad.headerLargeUrl;

    /**
     * End Constants
     */

    return (
        shouldShowNoPhotoBanner &&
        !isFetchingSquad && (
            <Banner
                config={{
                    isActive: true,
                    shouldShowChevron: true,
                    shouldShowIcon: true,
                }}
                onClick={() => {
                    setCurrentSection("edit-details");
                    setShouldShowCreateModal(true);
                }}
            >
                <Text isBold>
                    {t("detail.customize_banner_title", {
                        ns: "support_squad",
                    })}
                </Text>
                <Text>
                    {t("detail.customize_banner_subtitle", {
                        ns: "support_squad",
                    })}
                </Text>
            </Banner>
        )
    );
};

SupportSquadDetailBanner.propTypes = {
    isFetchingSquad: PropTypes.bool,
    setCurrentSection: PropTypes.func,
    setShouldShowCreateModal: PropTypes.func,
    selectedSquad: PropTypes.object,
};
