import { useEffect, useState } from "react";

// API
import { SandboxxRestAPI } from "utils/sandboxx";

// Media
import squadImagePlaceholder from "media/scenes/support-squad/squad-image-placeholder.png";

export const useSupportSquadListItem = ({ squad }) => {
    /**
     * useState
     */

    const [isFetchingImage, setIsFetchingImage] = useState(false);
    const [resolvedImage, setResolvedImage] = useState(null);

    /**
     * useEffect
     */

    useEffect(() => {
        if (!resolvedImage) {
            fetchImage();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resolvedImage, squad]);

    /**
     * End Hooks
     */

    function fetchImage() {
        if (squad?.headerLargeUrl) {
            setIsFetchingImage(true);
            SandboxxRestAPI.getImageCloudfront(
                squad.headerLargeUrl,
                onFetchImageSuccess,
                onFetchImageFailure
            );
        } else {
            setIsFetchingImage(false);
            setResolvedImage(squadImagePlaceholder);
        }
    }

    function onFetchImageFailure() {
        setIsFetchingImage(false);
    }

    function onFetchImageSuccess(res) {
        setIsFetchingImage(false);
        setResolvedImage(res);
    }

    return { isFetchingImage, resolvedImage };
};
