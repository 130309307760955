import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { DateTime } from "luxon";

// Components
import { DashboardHeader } from "./components/DashboardHeader/DashboardHeader";
import { DashboardMain } from "./DashboardMain";
import { GraduationModal } from "components/Graduation/GraduationModal";

// Context
import { BundlesContext } from "context/bundles";
import { CreditsContext } from "context/credits";
import { IterableContext } from "context/iterable/iterable";
import { NotificationBarContext } from "context/notificationBar";
import { PurchaseContext } from "context/purchase";

// CSS
import "css/dashboard/dashboard.scss";

// Hooks
import { useDocumentTitle } from "@uidotdev/usehooks";

// Localization
import { useTranslation } from "localization/localization";

// Logging
import { AnalyticsLogger } from "loggers/AnalyticsLogger";

// Models
import { SentLetter } from "models/letters/SentLetter";

// Utils
import { SandboxxRestAPI, CurrentUser } from "../../utils/sandboxx";
import { scrollToTop } from "../../utils/scrollUtils";

const Dashboard = (props) => {
    const { hasDepsShortCode, history, isUsingBundlesRoute } = props;

    /**
     * Custom Hooks
     **/

    const { t } = useTranslation();

    useDocumentTitle(t("document_title", { ns: "dashboard" }));

    /**
     * useContext
     **/

    const { bundles, fetchBundles } = useContext(BundlesContext);
    const { credits, fetchCredits } = useContext(CreditsContext);
    const { fetchEmbeddedMessages } = useContext(IterableContext);
    const { showNotification } = useContext(NotificationBarContext);
    const {
        fetchRecentPaymentMethod,
        fetchSavedCards,
        handlePreloadDiscount,
        setShowPurchaseModal,
    } = useContext(PurchaseContext);

    /**
     * useState
     **/

    const [activeDraft, setActiveDraft] = useState(null);
    const [connections, setConnections] = useState(null);
    const [currentActiveWriters, setCurrentActiveWriters] = useState(null);
    const [depLocation, setDepLocation] = useState(null);
    const [display, setDisplay] = useState({
        showGraduationModal: false,
        showMore: false,
    });
    const [graduation, setGraduation] = useState(null);
    const [letterCutOffTime, setLetterCutOffTime] = useState(null);
    const [lettersSent, setLettersSent] = useState(null);
    const [lettersSentCursor, setLettersSentCursor] = useState("");
    const [loading, setLoading] = useState({
        dashboard: true,
        letters: true,
    });
    const [promotion, setPromotion] = useState(null);
    const [promotions, setPromotions] = useState([]);
    const [weeklyUpdates, setWeeklyUpdates] = useState({
        graduation: null,
        selected: null,
        updates: null,
    });

    /**
     * useEffect
     **/

    useEffect(() => {
        scrollToTop();
        fetchAccount();
        fetchBundles({ recipientUserId: "", zipCode: "" });
        fetchCredits();
        fetchDashboard();
        fetchEmbeddedMessages();
        fetchGraduation();
        fetchKinConnections();
        fetchLettersSent();
        fetchRecentPaymentMethod();
        fetchSavedCards();
        AnalyticsLogger.logHomeView();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (hasDepsShortCode) {
            setDepsLocation();
        }
    }, [hasDepsShortCode]);

    useEffect(() => {
        if (isUsingBundlesRoute) {
            setShowPurchaseModal(isUsingBundlesRoute);
        }
    }, [isUsingBundlesRoute, setShowPurchaseModal]);

    useEffect(() => {
        showLastLetterSentNotification();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lettersSent]);

    /**
     * Constants
     **/

    const { showGraduationModal } = display;

    /**
     * End Hooks
     **/

    function fetchAccount() {
        SandboxxRestAPI.getAccount(onFetchAccountSuccess);
    }

    function fetchDashboard() {
        return SandboxxRestAPI.getDashboard(
            onFetchDashboardSuccess,
            onFetchDashboardError,
            onFetchDashboardError
        );
    }

    function fetchKinConnections() {
        return SandboxxRestAPI.getKinConnections(
            onFetchKinConnectionsSuccess,
            onFetchKinConnectionsError,
            onFetchKinConnectionsError
        );
    }

    function fetchGraduation() {
        return SandboxxRestAPI.getGraduation(onFetchGraduationSuccess);
    }

    function fetchLettersSent() {
        return SandboxxRestAPI.getLettersSent(
            lettersSentCursor,
            onFetchLettersSentSuccess,
            onFetchLettersSentError,
            onFetchLettersSentError
        );
    }

    function handleGraduationModalToggle() {
        setDisplay((prevDisplay) => ({
            ...prevDisplay,
            showGraduationModal: !prevDisplay.showGraduationModal,
        }));
    }

    function handlePurchaseModalToggle(discountCode) {
        discountCode &&
            typeof discountCode === "string" &&
            handlePreloadDiscount(discountCode);
        setShowPurchaseModal((prev) => !prev);
    }

    function onFetchAccountSuccess(res) {
        const { depLocation, onboarding, user, verification } = res;
        CurrentUser.storeUser(user, verification, onboarding);
        if (depLocation) {
            setDepLocation(depLocation);
        }
    }

    function onFetchDashboardError(err) {
        showNotification({
            text: err.message,
            type: "warning",
        });
    }

    function onFetchDashboardSuccess(res) {
        const {
            activeDraft,
            currentActiveWriters,
            graduation,
            letterCutOffTime,
            promoCarousel,
            promotion,
        } = res;
        setCurrentActiveWriters(currentActiveWriters);
        setActiveDraft(activeDraft);
        setGraduation(graduation);
        setLetterCutOffTime(letterCutOffTime);
        setPromotion(promotion);
        setPromotions(promoCarousel);
        setLoading((prevLoading) => ({ ...prevLoading, dashboard: false }));
    }

    function onFetchGraduationSuccess(graduation) {
        setWeeklyUpdates({ ...weeklyUpdates, graduation });
    }

    function onFetchKinConnectionsError(err) {
        showNotification({
            text: err.message,
            type: "warning",
        });
    }

    function onFetchKinConnectionsSuccess(res) {
        const connections = res.map((connection) => {
            return { ...connection, connected: true };
        });
        setLoading((prevLoading) => ({
            ...prevLoading,
            connections: false,
        }));
        setConnections(connections);
    }

    function onFetchLettersSentError(err) {
        showNotification({
            text: err.message,
            type: "warning",
        });
    }

    function onFetchLettersSentSuccess(res) {
        const lettersSentInstances = res.response.map(
            (letter) => new SentLetter(letter)
        );
        const updatedList = lettersSentCursor
            ? lettersSent.concat(lettersSentInstances)
            : lettersSentInstances;
        setLettersSent(updatedList);
        setLoading((prev) => ({
            ...prev,
            letters: false,
        }));
        setLettersSentCursor(res.cursor);
        showLastLetterSentNotification();
    }

    function setDepsLocation() {
        const depsShortCode = sessionStorage.getItem("deps_short_code");
        SandboxxRestAPI.setDepLocation({ depsShortCode });
    }

    function showLastLetterSentNotification() {
        const lastLetter = lettersSent && lettersSent[0];
        if (lastLetter) {
            const days = calculateDaysSinceLastLetter(lastLetter);
            if (days >= 5) {
                showNotification({
                    text: t("last_letter_sent", {
                        count: days,
                        ns: "notifications",
                    }),
                    type: "info",
                });
            }
        }
    }

    function renderContent() {
        return (
            <>
                <DashboardHeader
                    graduation={graduation}
                    handleGraduationModalToggle={handleGraduationModalToggle}
                    letterCutOffTime={letterCutOffTime}
                    lettersSent={lettersSent}
                />
                <DashboardMain
                    activeDraft={activeDraft}
                    bundles={bundles}
                    connections={connections}
                    credits={credits}
                    currentActiveWriters={currentActiveWriters}
                    depLocation={depLocation}
                    display={display}
                    fetchSentLetters={fetchLettersSent}
                    handlePurchaseModalToggle={handlePurchaseModalToggle}
                    history={history}
                    lettersSent={lettersSent}
                    loading={loading}
                    lettersSentCursor={lettersSentCursor}
                    promotion={promotion}
                    promotions={promotions}
                    weeklyUpdates={weeklyUpdates}
                />
            </>
        );
    }

    return (
        <div className="page">
            <GraduationModal
                fetchDashboard={fetchDashboard}
                graduation={graduation}
                onHide={handleGraduationModalToggle}
                show={showGraduationModal}
                showNotification={showNotification}
            />
            {renderContent()}
        </div>
    );
};

// withRouter
export const WrappedDashboard = withRouter(Dashboard);

function calculateDaysSinceLastLetter(letter) {
    const { created_at } = letter;
    const { days } = DateTime.local()
        .diff(DateTime.fromISO(created_at), ["days", "hours", "minutes"])
        .toObject();
    return Math.floor(days);
}
