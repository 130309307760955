import React from "react";

//Components
import { NotificationBar } from "../NotificationBar/NotificationBar";

// Context
import { BasesProvider } from "context/bases";
import { BundlesProvider } from "context/bundles";
import { CartProvider } from "context/Cart/cart";
import { ContactsProvider } from "context/contacts";
import { CreditsProvider } from "context/credits";
import { DepsProvider } from "context/deps";
import { HeaderBarProvider } from "context/headerBar";
import { InterstitialProvider } from "context/interstitial";
import { IterableProvider } from "context/iterable/iterable";
import { MusterProvider } from "context/muster";
import { NotificationProvider } from "context/notification/notification";
import { NotificationBarProvider } from "context/notificationBar";
import { PhoneVerificationProvider } from "context/phoneVerification";
import { PurchaseProvider } from "context/purchase";
import { RanksProvider } from "context/ranks";
import { TitlesProvider } from "context/titles";

export const Providers = ({ children }) => {
    return (
        <NotificationBarProvider>
            <NotificationBar />
            <NotificationProvider>
                <HeaderBarProvider>
                    <CreditsProvider>
                        <BundlesProvider>
                            <ContactsProvider>
                                <PhoneVerificationProvider>
                                    <PurchaseProvider>
                                        <CartProvider>
                                            <RanksProvider>
                                                <BasesProvider>
                                                    <DepsProvider>
                                                        <InterstitialProvider>
                                                            <TitlesProvider>
                                                                <MusterProvider>
                                                                    <IterableProvider>
                                                                        {
                                                                            children
                                                                        }
                                                                    </IterableProvider>
                                                                </MusterProvider>
                                                            </TitlesProvider>
                                                        </InterstitialProvider>
                                                    </DepsProvider>
                                                </BasesProvider>
                                            </RanksProvider>
                                        </CartProvider>
                                    </PurchaseProvider>
                                </PhoneVerificationProvider>
                            </ContactsProvider>
                        </BundlesProvider>
                    </CreditsProvider>
                </HeaderBarProvider>
            </NotificationProvider>
        </NotificationBarProvider>
    );
};
