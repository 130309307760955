import React, { useState } from "react";
import PropTypes from "prop-types";

// Components
import { Modal } from "components/Modals/Modal";
import { SupportSquadSettingsModalManageMembers } from "../SupportSquadSettingsModal/components/SupportSquadSettingsModalManage/components/SupportSquadSettingsModalManageMembers/SupportSquadSettingsModalManageMembers";
import { SupportSquadDeleteModalRemoveMemberWarning } from "./SupportSquadDeleteModalRemoveMemberWarning";
import { SupportSquadDeleteModalDeleteSquad } from "./SupportSquadDeleteModalDeleteSquad";

// CSS
import styles from "./support-squad-delete-modal.module.scss";

// Localization
import { useTranslation } from "localization/localization";

export const SupportSquadDeleteModal = (props) => {
    const {
        currentSection,
        deleteRequestPending,
        deleteSquad,
        removeSquadMembers,
        setCurrentSection,
        setShouldShowDeleteModal,
        shouldShowDeleteModal,
        squadMembers,
        selectedSquad,
    } = props;

    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * useState
     */

    const [hasAcceptedDeleteTerms, setHasAcceptedDeleteTerms] = useState(false);
    const [headerText, setHeaderText] = useState("");

    /**
     * End Hooks
     */

    function handleChangeCheckbox() {
        setHasAcceptedDeleteTerms((prev) => !prev);
    }

    function handleModalClose() {
        setShouldShowDeleteModal(false);
        setCurrentSection("");
        setHasAcceptedDeleteTerms(false);
        setHeaderText("");
    }

    function navigateToManageSquad() {
        setCurrentSection("manageSquad");
        setHeaderText(
            t("settings.manage_squad_members", {
                ns: "support_squad",
            })
        );
    }

    function renderModalContent() {
        switch (currentSection) {
            case "deleteSquad":
                return (
                    <SupportSquadDeleteModalDeleteSquad
                        deleteRequestPending={deleteRequestPending}
                        deleteSquad={deleteSquad}
                        handleChangeCheckbox={handleChangeCheckbox}
                        handleModalClose={handleModalClose}
                        hasAcceptedDeleteTerms={hasAcceptedDeleteTerms}
                        setHasAcceptedDeleteTerms={setHasAcceptedDeleteTerms}
                        selectedSquad={selectedSquad}
                    />
                );
            case "manageSquad":
                return (
                    <SupportSquadSettingsModalManageMembers
                        removeSquadMembers={removeSquadMembers}
                        setCurrentSection={setCurrentSection}
                        squadMembers={squadMembers}
                        selectedSquad={selectedSquad}
                    />
                );
            case "removeMemberWarning":
                return (
                    <SupportSquadDeleteModalRemoveMemberWarning
                        handleModalClose={handleModalClose}
                        navigateToManageSquad={navigateToManageSquad}
                        selectedSquad={selectedSquad}
                    />
                );
            default:
                break;
        }
    }

    return (
        <Modal
            onHide={handleModalClose}
            show={shouldShowDeleteModal}
            centered="true"
            className={styles["support-squad-delete-modal"]}
            shouldHideHeader={!headerText}
            headerText={headerText}
        >
            {renderModalContent()}
        </Modal>
    );
};

SupportSquadDeleteModal.propTypes = {
    currentSection: PropTypes.string.isRequired,
    deleteRequestPending: PropTypes.bool.isRequired,
    deleteSquad: PropTypes.func.isRequired,
    removeSquadMembers: PropTypes.func.isRequired,
    setCurrentSection: PropTypes.func.isRequired,
    setShouldShowDeleteModal: PropTypes.func.isRequired,
    shouldShowDeleteModal: PropTypes.bool.isRequired,
    squadMembers: PropTypes.array.isRequired,
    selectedSquad: PropTypes.object,
};
