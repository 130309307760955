import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import sha256 from "crypto-js/sha256";

// Components
import { ButtonPrimary, ButtonSecondary } from "components/Buttons/Buttons";
import { Panel } from "components/Panel/Panel";
import { SupportSquadListEmpty } from "./components/SupportSquadListEmpty/SupportSquadListEmpty";
import { SupportSquadListItem } from "./components/SupportSquadListItem/SupportSquadListItem";
import { Text } from "components/Text/Text";

// CSS
import styles from "./support-squad-list.module.scss";

// Hooks
import { useSupportSquadList } from "./hooks/useSupportSquadList";

// Localization
import { useTranslation } from "localization/localization";

export const SupportSquadList = ({
    handleSelectSquad,
    isFetchingSquads,
    pageBodyVisibleDimensions,
    setCurrentSection,
    setShouldShowCreateModal,
    setShouldShowJoinModal,
    setSquadName,
    setSelectedSquad,
    setSupportSquadImage,
    squadList,
    selectedSquad,
}) => {
    /**
     * Custom Hooks
     */

    const { handleCreateSquadClick, handleJoinSquadClick } =
        useSupportSquadList({
            setCurrentSection,
            setShouldShowCreateModal,
            setShouldShowJoinModal,
            setSquadName,
            setSelectedSquad,
            setSupportSquadImage,
        });
    const { t } = useTranslation();

    /**
     * End Hooks
     */

    function renderBody() {
        if (isFetchingSquads) {
            return renderLoading();
        } else if (squadList.length) {
            return renderSquads();
        } else {
            return <SupportSquadListEmpty />;
        }
    }

    function renderButtons() {
        return (
            <div className={styles.buttons}>
                <ButtonPrimary
                    classes={styles.button}
                    cypressTestId="create-squad-button"
                    onClick={handleCreateSquadClick}
                >
                    <Text>{t("create_squad", { ns: "support_squad" })}</Text>
                </ButtonPrimary>
                <ButtonSecondary
                    classes={styles.button}
                    cypressTestId="join-squad-button"
                    onClick={handleJoinSquadClick}
                >
                    <Text>{t("join_squad", { ns: "support_squad" })}</Text>
                </ButtonSecondary>
            </div>
        );
    }

    function renderLoading() {
        return (
            <div className={cx(styles.body, styles["body--loading"])}>
                <div className={styles.squadList}>
                    <SupportSquadListItem isLoading={true} />
                    <SupportSquadListItem isLoading={true} />
                </div>
            </div>
        );
    }

    function renderSquads() {
        return (
            <div className={cx(styles.body)}>
                <div className={styles.squadList}>
                    {squadList.map((squad, i) => {
                        const key = `${squad.squadId}-${sha256(
                            squad.headerLargeUrl
                        )}`;
                        return (
                            <SupportSquadListItem
                                handleSelectSquad={handleSelectSquad}
                                index={i}
                                isSelected={
                                    squad.squadId === selectedSquad?.squadId
                                }
                                key={key}
                                squad={squad}
                            />
                        );
                    })}
                </div>
            </div>
        );
    }

    return (
        <Panel
            classes={styles.supportSquadList}
            style={{
                height: `calc(${pageBodyVisibleDimensions?.visibleHeight}px + 24px)`,
            }}
        >
            {renderBody()}
            {renderButtons()}
        </Panel>
    );
};

SupportSquadList.propTypes = {
    handleSelectSquad: PropTypes.func,
    isFetchingSquads: PropTypes.bool,
    pageBodyVisibleDimensions: PropTypes.object,
    setCurrentSection: PropTypes.func,
    setShouldShowCreateModal: PropTypes.func,
    setShouldShowJoinModal: PropTypes.func,
    setSquadName: PropTypes.func,
    setSelectedSquad: PropTypes.func,
    setSupportSquadImage: PropTypes.func,
    squadList: PropTypes.array,
    selectedSquad: PropTypes.object,
};
