import React, { useEffect } from "react";
import PropTypes from "prop-types";

// Components
import { ButtonPrimary } from "components/Buttons/Buttons";
import { CheckmarkSuccessIcon } from "components/Icons/CheckmarkSuccessIcon";
import { Text } from "components/Text/Text";

// CSS
import styles from "./support-squad-donate-tokens-modal-success.module.scss";

// Localization
import { useTranslation } from "localization/localization";

/**
 * TODO: Replace this with small modal configuration when available (and adjusting checkmark size according to the designs)
 */
export const SupportSquadDonateTokensModalSuccess = (props) => {
    const {
        handleCloseModal,
        setHeaderText,
        setHeaderSubText,
        selectedSquad,
        tokensToDonate,
    } = props;

    const { t } = useTranslation("support-squad");

    /**
     * useEffect
     */

    useEffect(() => {
        setHeaderText("");
        setHeaderSubText("");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Constants
     */

    const lettersText = t("letter_ip", {
        count: tokensToDonate,
        ns: "common",
    });

    /**
     * End Hooks
     */

    return (
        <div className={styles["support-squad-donate-tokens-modal-success"]}>
            <CheckmarkSuccessIcon
                className={styles.checkmarkSuccess}
                height="64"
                width="64"
            />
            <div className={styles.text}>
                <p>
                    {t("donate.success", {
                        letters: lettersText,
                        ns: "support_squad",
                        supporteeFirstName: selectedSquad.supporteeFirstName,
                        tokensToDonate,
                    })}
                </p>
            </div>
            <div className={styles.buttons}>
                <ButtonPrimary
                    classes={styles.button}
                    onClick={handleCloseModal}
                    text="Continue"
                    type="button"
                >
                    <Text>
                        {t("continue", {
                            ns: "common",
                        })}
                    </Text>
                </ButtonPrimary>
            </div>
        </div>
    );
};

SupportSquadDonateTokensModalSuccess.propTypes = {
    handleCloseModal: PropTypes.func.isRequired,
    setHeaderText: PropTypes.func.isRequired,
    setHeaderSubText: PropTypes.func.isRequired,
    tokensToDonate: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.oneOf([""]),
    ]),
};
