import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// CSS
import styles from "./support-squad-settings-modal-manage.module.scss";

// Components
import { SupportSquadSettingsModalManageOptions } from "./components/SupportSquadSettingsModalManageOptions/SupportSquadSettingsModalManageOptions";
import { SupportSquadSettingsModalManageUsers } from "./components/SupportSquadSettingsModalManageUsers/SupportSquadSettingsModalManageUsers";

// Localization
import { useTranslation } from "localization/localization";

export const SupportSquadSettingsModalManage = (props) => {
    const { setHeaderSubText, setHeaderText } = props;

    /**
     * Custom Hooks
     **/

    const { t } = useTranslation();

    /**
     * useEffect
     **/

    useEffect(() => {
        setHeaderText(t("settings.manage_title", { ns: "support_squad" }));
        setHeaderSubText("");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * useState
     **/

    const [settingsSection, setSettingsSection] = useState("options");

    /**
     * End Hooks
     **/

    function renderModalContent() {
        switch (settingsSection) {
            case "options":
                return (
                    <SupportSquadSettingsModalManageOptions
                        {...props}
                        setSettingsSection={setSettingsSection}
                    />
                );
            case "manage-members":
                return (
                    <SupportSquadSettingsModalManageUsers
                        {...props}
                        setModalHeaderText={setHeaderText}
                    />
                );
            default:
                break;
        }
    }

    return (
        <div
            className={styles["support-squad-settings-modal-manage"]}
            data-cy="support-squad-settings-modal-manage"
        >
            <div className="modal__body">{renderModalContent()}</div>
        </div>
    );
};

SupportSquadSettingsModalManage.propTypes = {
    setHeaderSubText: PropTypes.func.isRequired,
    setHeaderText: PropTypes.func.isRequired,
};
