import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { Skeleton } from "@material-ui/lab";

// Components
import { Avatar } from "components/Avatar/Avatar";
import { Checkbox } from "components/Inputs/Checkbox/Checkbox";

// CSS
import styles from "./support-squad-settings-modal-manage-user-list-item.module.scss";

// Enums
import { SquadRole } from "scenes/SupportSquad/enums/SquadRole";

// Localization
import { useTranslation } from "localization/localization";

export const SupportSquadSettingsModalManageUserListItem = ({
    checkbox,
    classes,
    index,
    isLoading,
    onClick,
    onKeyPress,
    user,
    tabIndex,
}) => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * End Hooks
     */

    function renderAvatar() {
        if (isLoading) {
            return (
                <Skeleton
                    height={40}
                    width={40}
                    variant="circle"
                    style={{ marginRight: "16px" }}
                />
            );
        } else {
            const name = user.memberFullName
                ? user.memberFullName.split(" ")
                : ["U", "K"];
            const directImageSrc = user.memberPhotoUrl;
            return (
                <Avatar
                    additionalClasses={styles.avatar}
                    directImageSrc={directImageSrc}
                    user={{
                        firstName: name[0],
                        fullName: user.memberFullName,
                        lastName: name[1],
                    }}
                />
            );
        }
    }

    function renderCheckbox() {
        const { checked, onChange, shouldShow, type } = checkbox;
        if (!isLoading && shouldShow) {
            return (
                <Checkbox
                    checked={checked}
                    cyId={"support-squad-manage-user-checkbox-" + index}
                    classes={styles.checkbox}
                    id={`checkbox-${index}`}
                    onChange={onChange}
                    type={type}
                />
            );
        }
    }

    function renderName() {
        if (isLoading) {
            return <Skeleton height={16} width={150} />;
        } else {
            return (
                <span className={styles.name}>
                    {user.memberFullName
                        ? user.memberFullName
                        : t("unknown", { ns: "common" })}
                </span>
            );
        }
    }

    return (
        <div
            className={cx(
                styles["support-squad-manage-user-list-item"],
                classes
            )}
            data-cy={
                user.role === SquadRole.ADMIN
                    ? "support-squad-manage-user-admin-" + index
                    : "support-squad-manage-user-member-" + index
            }
            onClick={onClick}
            onKeyDown={onKeyPress}
            tabIndex={tabIndex}
        >
            <div className={styles.details}>
                {renderAvatar()}
                {renderName()}
            </div>
            {renderCheckbox()}
        </div>
    );
};

SupportSquadSettingsModalManageUserListItem.defaultProps = { checkbox: {} };

SupportSquadSettingsModalManageUserListItem.propTypes = {
    checkbox: PropTypes.object,
    classes: PropTypes.string,
    index: PropTypes.number,
    onClick: PropTypes.func,
    onKeyPress: PropTypes.func,
    user: PropTypes.object,
    tabIndex: PropTypes.string,
};
