import { useContext, useRef, useState } from "react";
import * as Sentry from "@sentry/react";

// Context
import { NotificationBarContext } from "context/notificationBar";

// Localization
import { useTranslation } from "localization/localization";

// Utils
import {
    generateBlobFromOffscreenCanvas,
    generateInitialCrop,
    resizeFinalImage,
} from "../utils/utils";

export const useImageCropModal = ({
    aspect,
    finalWidth,
    handleSaveCroppedImage,
}) => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * useContext
     */

    const { showNotification } = useContext(NotificationBarContext);

    /**
     * useState
     */

    const [crop, setCrop] = useState(null);
    const [completedCrop, setCompletedCrop] = useState(null);

    /**
     * useRef
     */

    const imageRef = useRef(null);

    /**
     * End Hooks
     */

    function handleSetInitialCrop(e, imageRef) {
        if (imageRef.current) {
            const initialCrop = generateInitialCrop(imageRef.current, aspect);
            setCrop(initialCrop);
            setCompletedCrop(initialCrop);
        }
    }

    /**
     * Prepares image for final submission API. Steps include...
     *  1. Create canvas
     *  2. Draw cropped image onto canvas
     *  3. Generate image blob and URL from canvas
     *  4. Resize image blob to meet server specs
     *  5. Submit final image blob and URL to scene
     */
    async function handleSubmit(e) {
        try {
            const image = imageRef.current;
            const scaleX = image.naturalWidth / image.width;
            const scaleY = image.naturalHeight / image.height;

            /**
             * Create canvas
             */
            const offscreen = new OffscreenCanvas(
                completedCrop.width * scaleX,
                completedCrop.height * scaleY
            );

            /**
             * Draw cropped image onto canvas by applying and scaling
             * crop information to the original image file
             */
            const ctx = offscreen.getContext("2d");
            ctx.drawImage(
                image,
                completedCrop.x * scaleX,
                completedCrop.y * scaleY,
                completedCrop.width * scaleX,
                completedCrop.height * scaleY,
                0,
                0,
                offscreen.width,
                offscreen.height
            );

            /**
             * Generate image blob and URL from canvas
             */
            const { blob, url } = await generateBlobFromOffscreenCanvas(
                offscreen
            );

            /**
             * Resize image blob so that it meets server specs
             */
            const imageFile = await resizeFinalImage(blob, url, {
                width: finalWidth,
            });

            /**
             * Submit final blob and URL to scene
             */
            handleSaveCroppedImage({
                imageFile,
                imagePreviewUrl: url,
            });
        } catch (err) {
            Sentry.captureException(err);
            showNotification({
                text: t("crop_image_error", { ns: "notifications" }),
                type: "warning",
            });
        }
    }

    return {
        completedCrop,
        crop,
        handleSetInitialCrop,
        handleSubmit,
        imageRef,
        setCompletedCrop,
        setCrop,
    };
};
