import { useContext, useMemo } from "react";

// Context
import { IterableContext } from "context/iterable/iterable";

// Enums
import { IterableEmbeddedMessagesPlacementId } from "enums/iterable/IterableEmbeddedMessagePlacementId";

// Utils
import { DEV } from "utils/urlUtils";

// Constants
const embeddedMessagesPlacementId = DEV
    ? IterableEmbeddedMessagesPlacementId.DASHBOARD_MESSAGES_STAGE
    : IterableEmbeddedMessagesPlacementId.DASHBOARD_MESSAGES_PROD;

export const useDashboardMessages = () => {
    /**
     * useContext
     */

    const { embeddedMessages, isLoadingEmbeddedMessages } =
        useContext(IterableContext);

    /**
     * useMemo
     */

    const messages = useMemo(() => {
        if (!embeddedMessages) return null;
        return embeddedMessages[embeddedMessagesPlacementId];
    }, [embeddedMessages]);

    /**
     * End Hooks
     */

    return { isLoadingEmbeddedMessages, messages };
};
