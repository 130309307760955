// Enums
import { CookieName } from "enums/CookieName";

// Interfaces
import { CookieManager } from "interfaces/CookieManager";

/**
 * Add an alert to the dismissed message ids list in the cookie
 * @param {Object} alert - The alert to be added to the dismissed message ids list
 * @returns {Boolean} - Returns true if added successfully, false if not added
 */
export function addAlertToDismissedMessageIdsList(alert) {
    try {
        const { ids, idsEncoded } = getDismissedMessageIdsFromCookie();
        const maxByteSize = 4000;
        const name = CookieName.DASHBOARD_ALERTS_DISMISSED;
        const expires = new Date();
        expires.setTime(expires.getTime() + 24 * 60 * 60 * 1000);

        // If (a) there is no list present in cookie, or (b) the list is not valid,
        // then create one that contains the dismissed message id, and set its
        // expiry date to 24 hours from now
        if (!idsEncoded || !Array.isArray(ids)) {
            const value = JSON.stringify([alert.metadata.messageId]);
            CookieManager.setCookie({
                expires,
                name,
                value,
            });
            return true;
        }

        // Otherwise, add dismissed message id to list
        CookieManager.addToCookieArray({
            config: { areEntriesUnique: true },
            entry: alert.metadata.messageId,
            expires,
            maxByteSize,
            name,
        });
        return true;
    } catch (error) {
        return false;
    }
}

/**
 * Check if an alert was already dismissed by user
 * @param {Object} alert - The alert to be checked for dismissal
 * @returns {Boolean} - Returns true if dismissed, false if not dismissed
 */
export function checkIfAlertWasDismissed(alert) {
    try {
        const { ids, idsEncoded } = getDismissedMessageIdsFromCookie();

        // If (a) there is no list present in cookie, or (b) the list is not valid,
        // then display the alert
        if (!idsEncoded || !Array.isArray(ids)) {
            return false;
        }

        // Return boolean representing whether the alert was dismissed
        return ids.includes(alert.metadata.messageId);
    } catch (error) {
        return false;
    }
}

/**
 * Get the dismissed message ids list from the cookie
 * @returns {Object}
 *  @returns {Array|Null} ids - The ids array
 *  @returns {String|Null} idsEncoded - The encoded ids string
 */
export function getDismissedMessageIdsFromCookie() {
    try {
        const idsEncoded = CookieManager.getCookie(
            CookieName.DASHBOARD_ALERTS_DISMISSED
        );
        const ids = JSON.parse(idsEncoded);
        return {
            ids,
            idsEncoded,
        };
    } catch (error) {
        return { ids: null, idsEncoded: null };
    }
}
