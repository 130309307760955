import { useContext, useState } from "react";

// Context
import { NotificationContext } from "context/notification/notification";

// Enums
import { NavigationEnum } from "../constants/constants";
import { NotificationSeverity } from "enums/notifications/NotificationSeverity";

// Localization
import { t } from "localization/localization";

// Loggers
import { AnalyticsLogger } from "loggers/AnalyticsLogger";

// Models
import { Notification } from "models/notification/Notification";

// Utils
import { SandboxxRestAPI } from "utils/sandboxx";

export const useSupportSquadDonateTokensModal = ({
    fetchCredits,
    handleSelectSquad,
    squadList,
    selectedSquad,
}) => {
    /**
     * useContext
     */

    const { addNotification } = useContext(NotificationContext);

    /**
     * useState
     */

    const [currentSection, setCurrentSection] = useState(
        NavigationEnum.ENTER_AMOUNT
    );
    const [hasAgreedToDonationTerms, setHasAgreedToDonationTerms] =
        useState(false);
    const [headerText, setHeaderText] = useState(
        t("donate.enter_amount_title", { ns: "support_squad" })
    );
    const [headerSubText, setHeaderSubText] = useState(
        t("donate.enter_amount_subtitle", {
            ns: "support_squad",
            supporteeFirstName: selectedSquad?.supporteeFirstName,
        })
    );
    const [loading, setLoading] = useState(false);
    const [tokensToDonate, setTokensToDonate] = useState("");

    /**
     * End Hooks
     */

    function handleDonateTokens() {
        setLoading(true);
        return SandboxxRestAPI.donateTokens(
            {
                squadId: selectedSquad?.squadId,
                tokenDonationAmount: tokensToDonate,
            },
            () => onDonateTokensSuccess(tokensToDonate),
            onDonateTokensFailure,
            onDonateTokensFailure
        );
    }

    function handleChangeTokensToDonate(e) {
        const tokens = e.target.value === "" ? "" : parseInt(e.target.value);
        if (tokens >= 0) {
            setTokensToDonate(tokens);
        }
    }

    function onDonateTokensFailure({ message }) {
        setLoading(false);
        addNotification(
            new Notification({
                id: "DONATE_TOKENS_FAILURE",
                message: message,
                options: { severity: NotificationSeverity.ERROR },
            })
        );
    }

    function onDonateTokensSuccess(tokensToDonate) {
        AnalyticsLogger.logSquadDonateTokensNumber(tokensToDonate);
        handleSelectSquad(
            squadList.find((squad) => squad.squadId === selectedSquad?.squadId)
        );
        fetchCredits();
        setCurrentSection(NavigationEnum.SUCCESS);
        setLoading(false);
    }

    function resetModal() {
        setCurrentSection(NavigationEnum.ENTER_AMOUNT);
        setHeaderText(t("donate.enter_amount_title", { ns: "support_squad" }));
        setHeaderSubText(
            t("donate.enter_amount_subtitle", {
                ns: "support_squad",
                supporteeFirstName: selectedSquad?.supporteeFirstName,
            })
        );
        setTokensToDonate(0);
        setHasAgreedToDonationTerms(false);
    }

    return {
        currentSection,
        handleChangeTokensToDonate,
        handleDonateTokens,
        hasAgreedToDonationTerms,
        headerText,
        headerSubText,
        loading,
        resetModal,
        setCurrentSection,
        setHasAgreedToDonationTerms,
        setHeaderText,
        setHeaderSubText,
        tokensToDonate,
    };
};
