import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// Components
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

// CSS
import styles from "./support-squad-settings-modal-manage-options.module.scss";

// Localization
import { useTranslation } from "localization/localization";

// Loggers
import { AnalyticsLogger } from "loggers/AnalyticsLogger";

// Utils
import { handleKeyDown } from "utils/eventUtils";

export const SupportSquadSettingsModalManageOptions = ({
    onDeleteSquadClick,
    openContactModalEdit,
    setCurrentSection,
    setSettingsSection,
}) => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * End Hooks
     */

    return (
        <div className={styles["support-squad-settings-modal-manage-options"]}>
            <div
                className={styles.squadOptions}
                data-cy="support-squad-settings-view"
            >
                <span className={styles.header}>
                    {t("support_squad_singular", {
                        ns: "common",
                    }).toUpperCase()}
                </span>
                <div
                    className={styles.option}
                    data-cy="support-squad-settings-edit-details-button"
                    onClick={() => {
                        setCurrentSection("edit-details");
                        AnalyticsLogger.logSquadUpdateSquadDetailsSelected();
                    }}
                    onKeyDown={(e) =>
                        handleKeyDown(e, () => {
                            setCurrentSection("edit-details");
                            AnalyticsLogger.logSquadUpdateSquadDetailsSelected();
                        })
                    }
                    tabIndex="0"
                >
                    <span>
                        {t("settings.manage_update_details", {
                            ns: "support_squad",
                        })}
                    </span>
                    <ChevronRightIcon
                        height="32px"
                        width="32px"
                        color="#9b9b9b"
                    />
                </div>
                <div
                    className={styles.option}
                    data-cy="support-squad-settings-edit-recipient-address-button"
                    onClick={() => {
                        openContactModalEdit();
                        AnalyticsLogger.logSquadUpdateRecipientAddressSelected();
                    }}
                    onKeyDown={(e) =>
                        handleKeyDown(e, () => {
                            openContactModalEdit();
                            AnalyticsLogger.logSquadUpdateRecipientAddressSelected();
                        })
                    }
                    tabIndex="0"
                >
                    <span>
                        {t("settings.manage_update_address", {
                            ns: "support_squad",
                        })}
                    </span>
                    <ChevronRightIcon
                        height="32px"
                        width="32px"
                        color="#9b9b9b"
                    />
                </div>
            </div>
            <div className={styles.membershipOptions}>
                <span className={styles.header}>
                    {t("settings.manage_membership", {
                        ns: "support_squad",
                    }).toUpperCase()}
                </span>
                <div
                    className={styles.option}
                    data-cy="support-squad-settings-manage-members-button"
                    onClick={() => {
                        setSettingsSection("manage-members");
                        AnalyticsLogger.logSquadViewMembersSelected();
                    }}
                    onKeyDown={(e) =>
                        handleKeyDown(e, () => {
                            setSettingsSection("manage-members");
                            AnalyticsLogger.logSquadViewMembersSelected();
                        })
                    }
                    tabIndex="0"
                >
                    <span>
                        {t("settings.manage_members", {
                            ns: "support_squad",
                        })}
                    </span>
                    <ChevronRightIcon
                        height="32px"
                        width="32px"
                        color="#9b9b9b"
                    />
                </div>
                <div
                    className={styles.option}
                    data-cy="support-squad-settings-invite-supporters-button"
                    onClick={() => {
                        setCurrentSection("invite");
                        AnalyticsLogger.logSquadInviteSupportersSelected();
                    }}
                    onKeyDown={(e) =>
                        handleKeyDown(e, () => {
                            setCurrentSection("invite");
                            AnalyticsLogger.logSquadInviteSupportersSelected();
                        })
                    }
                    tabIndex="0"
                >
                    <span>
                        {t("settings.manage_invite", {
                            ns: "support_squad",
                        })}
                    </span>
                    <ChevronRightIcon
                        height="32px"
                        width="32px"
                        color="#9b9b9b"
                    />
                </div>
                <div
                    className={cx(styles.option, styles["option--red"])}
                    data-cy="support-squad-settings-delete-squad-button"
                    onClick={onDeleteSquadClick}
                    onKeyDown={(e) => handleKeyDown(e, onDeleteSquadClick)}
                    tabIndex="0"
                >
                    {t("settings.manage_delete", {
                        ns: "support_squad",
                    })}
                </div>
            </div>
        </div>
    );
};

SupportSquadSettingsModalManageOptions.propTypes = {
    onDeleteSquadClick: PropTypes.func.isRequired,
    openContactModalEdit: PropTypes.func.isRequired,
    setCurrentSection: PropTypes.func.isRequired,
    setSettingsSection: PropTypes.func.isRequired,
};
