import React from "react";
import cx from "classnames";

// Components
import { Alert } from "components/Alert/Alert";
import { CarouselV2 } from "components/CarouselV2/CarouselV2";
import { Text } from "components/Text/Text";

// CSS
import styles from "./dashboard-alerts.module.scss";

// Hooks
import { useDashboardAlerts } from "./hooks/useDashboardAlerts";

export const DashboardAlerts = () => {
    /**
     * Custom Hooks
     */

    const {
        alerts,
        containerHeight,
        containerRef,
        handleCloseAlert,
        hasRenderedCarousel,
    } = useDashboardAlerts();

    /**
     * End Hooks
     */

    function renderAlerts() {
        if (alerts.length <= 1 && !hasRenderedCarousel) {
            return alerts.map((alert) => (
                <Alert
                    className={styles.alert}
                    config={{ shouldShowClose: true }}
                    key={alert.metadata.messageId}
                    onClose={() => handleCloseAlert(alert)}
                >
                    <Text isBold>{alert.elements.title}</Text>
                    <Text>{alert.elements.body}</Text>
                </Alert>
            ));
        } else {
            return (
                <CarouselV2
                    classNames={{
                        container: styles.carousel,
                        item: styles.item,
                        wrapper: "",
                    }}
                    gap={24}
                    title="ALERTS"
                >
                    {alerts.map((alert) => (
                        <Alert
                            className={cx(
                                styles.alert,
                                styles["alert--inCarousel"]
                            )}
                            config={{ shouldShowClose: true }}
                            key={alert.metadata.messageId}
                            onClose={() => handleCloseAlert(alert)}
                        >
                            <Text isBold>{alert.elements.title}</Text>
                            <Text>{alert.elements.body}</Text>
                        </Alert>
                    ))}
                </CarouselV2>
            );
        }
    }

    if (alerts) {
        return (
            <div
                className={cx(styles.dashboardAlerts, {
                    [styles["dashboardAlerts--hasAlerts"]]: alerts?.length,
                })}
                ref={containerRef}
                style={{ height: containerHeight }}
            >
                {renderAlerts()}
            </div>
        );
    } else {
        return null;
    }
};
