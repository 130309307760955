import { initialize } from "@iterable/web-sdk/dist/authorization";
import { trackPurchase } from "@iterable/web-sdk/dist/commerce";
import {
    track,
    trackInAppConsume,
    trackInAppOpen,
} from "@iterable/web-sdk/dist/events";
import { getInAppMessages } from "@iterable/web-sdk/dist/inapp";

// Constants
import { appPackageName, iterableApiKey } from "./constants/constants";

// Interfaces
import IterableInboxManager from "interfaces/inbox/IterableInboxManager";

// Utils
import { emptyFunction } from "utils/miscUtils";
import { CurrentUser, SandboxxRestAPI } from "utils/sandboxx";
import {
    formatIterableEmbeddedMessages,
    generateIterableCampaignIdPayload,
    generateUserIdentifier,
} from "./utils/utils";

export class IterableSDK {
    constructor() {
        this.hasFailedToFetchJWT = false;
        this.hasMadeInitialEmbeddedMessagesFetch = false;
        this.hasInitialized = false;
    }

    deleteInboxMessage(message, { onFailure, onSuccess }) {
        return trackInAppConsume({
            deviceInfo: { appPackageName },
            messageId: message.id,
        })
            .then(onSuccess)
            .catch(onFailure);
    }

    /**
     * Fetches all of a user's embedded messages from Iterable
     *
     * @returns {Promise}
     */
    async getEmbeddedMessages() {
        try {
            // Fetch and format embedded messages
            const { json } =
                await SandboxxRestAPI.getIterableEmbeddedMessages();
            const embeddedMessages = formatIterableEmbeddedMessages({ json });

            // Only submit received events for each message after the first fetch
            if (!this.hasMadeInitialEmbeddedMessagesFetch) {
                this.trackEmbeddedMessageReceivedEventBatch({
                    embeddedMessages,
                });
            }

            // Turn on initial fetch flag
            this.hasMadeInitialEmbeddedMessagesFetch = true;

            // Return embedded messages from function
            return embeddedMessages;
        } catch (error) {
            return {};
        }
    }

    getInboxMessages({ onFailure, onSuccess }) {
        return getInAppMessages(
            { count: 20, packageName: appPackageName },
            { display: "deferred" }
        )
            .request()
            .then(onSuccess)
            .catch(onFailure);
    }

    /**
     * identify
     * ========
     * This method identifies a user and provides it to Iterable. It is only
     * triggered if a userId is available.
     *
     * If a userId is available, Iterable's `setUserId` method is called,
     * which updates the userId both locally and through the Iterable API.
     *
     * The `updateUserEmail` method then sends the email through Iterable's
     * API.
     */
    identify({ onIdentifySuccess = emptyFunction } = {}) {
        const user = CurrentUser.getUser() || {};
        const identifier = generateUserIdentifier(user);
        if (identifier && !this.hasFailedToFetchJWT) {
            this.setEmail(identifier)
                .then((res) => {
                    this.hasInitialized = true;
                    IterableInboxManager.fetchMessages();
                    onIdentifySuccess();
                })
                .catch((err) => {});
        }
    }

    /**
     * init
     * ====
     * This method initializes the Iterable SDK.
     *
     * The SDK's `initialize`
     * method returns four methods -- clearRefresh, logout, setEmail, and
     * setUserID -- that are saved to and can be used elsewhere in this
     * platform class.
     *
     * The last step of the method triggers this class's `identify` method
     */
    init() {
        const { clearRefresh, logout, setEmail, setUserID } = initialize(
            iterableApiKey,
            /**
             * This callback is triggered whenever the Iterable SDK needs to retrieve
             * or renew a JWT token
             *
             * @returns {Promise} that resolves with the API-provided JWT token
             */
            () => {
                return SandboxxRestAPI.getIterableJWT(
                    ({ jwtReturned }) => jwtReturned,
                    (err) => {
                        this.hasFailedToFetchJWT = true;
                    }
                );
            }
        );
        this.clearRefresh = clearRefresh;
        this.logout = logout;
        this.setEmail = setEmail;
        this.setUserId = setUserID;
        this.identify();
    }

    logout() {
        this.logout();
    }

    markInboxMessageAsRead(message) {
        trackInAppOpen({
            deviceInfo: { appPackageName },
            messageId: message.id,
        })
            .then((res) => {})
            .catch((err) => {});
    }

    /**
     * Trigger click event tracking for an individual embedded message
     *
     * @param {Object} options
     * @param {Object} options.message
     * @param {Function?} options.onFailure
     * @param {Function?} options.onSuccess
     * @returns {Promise}
     */
    trackEmbeddedMessageClickEvent({ message, onFailure, onSuccess }) {
        const buttonId =
            message?.elements?.buttons && message?.elements?.buttons[0]?.id;
        if (buttonId) {
            const payload = {
                ...(buttonId ? { buttonId } : {}),
                messageId: message?.metadata?.messageId,
                targetUrl: message?.elements?.buttons[0]?.action?.data,
            };
            return SandboxxRestAPI.trackIterableEmbeddedMessageClickEvent({
                payload,
                onFailure,
                onSuccess,
            });
        }
    }

    /**
     * Trigger received event tracking for an individual embedded message
     *
     * @param {Object} options
     * @param {Object} options.message
     * @param {Function?} options.onFailure
     * @param {Function?} options.onSuccess
     * @returns {Promise}
     */
    trackEmbeddedMessageReceivedEvent({ message, onFailure, onSuccess }) {
        const payload = { messageId: message.metadata.messageId };
        return SandboxxRestAPI.trackIterableEmbeddedMessageReceivedEvent(
            payload,
            onSuccess,
            onFailure
        );
    }

    /**
     * Trigger received event tracking for a batch of embedded messages
     *
     * @param {Object} options
     * @param {Array} options.messages
     * @param {Function?} options.onFailure
     * @param {Function?} options.onSuccess
     * @returns {Promise}
     */
    trackEmbeddedMessageReceivedEventBatch({
        embeddedMessages,
        onFailure,
        onSuccess,
    }) {
        Object.keys(embeddedMessages).forEach((key) => {
            embeddedMessages[key].forEach((message) => {
                this.trackEmbeddedMessageReceivedEvent({
                    message,
                    onFailure,
                    onSuccess,
                });
            });
        });
    }

    trackEvent(eventName, data) {
        const campaignId = generateIterableCampaignIdPayload();
        track({ dataFields: data, eventName, ...campaignId })
            .then((res) => {})
            .catch((err) => {});
    }

    trackPurchaseEvent({ items, total }) {
        const campaignId = generateIterableCampaignIdPayload();
        trackPurchase({ ...campaignId, items, total })
            .then((res) => {})
            .catch((err) => {});
    }
}

export const Iterable = new IterableSDK();
