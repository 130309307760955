import React from "react";
import PropTypes from "prop-types";

// Components
import AddIcon from "@mui/icons-material/Add";
import { ButtonInvisible, ButtonPrimary } from "components/Buttons/Buttons";
import { Text } from "components/Text/Text";
import { TextFieldValidated } from "components/Inputs/TextFieldValidated/TextFieldValidated";

// CSS
import styles from "./support-squad-settings-modal-create-invite.module.scss";

// Hooks
import { useSupportSquadSettingsModalCreateInvite } from "./hooks/useSupportSquadSettingsModalCreateInvite";

// Localization
import { useTranslation } from "localization/localization";

// Utils
import { handleKeyDown } from "utils/eventUtils";
import { Validation } from "validation/validation";

export const SupportSquadSettingsModalCreateInvite = (props) => {
    const {
        handleAddEmail,
        handleEmailChange,
        handleModalClose,
        handleSubmitEmailInvites,
        inviteEmails,
        hasEnteredAnEmail,
        isSendingEmails,
        numberOfEmails,
        setHeaderSubText,
        setHeaderText,
        squadName,
        selectedSquad,
    } = props;

    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    useSupportSquadSettingsModalCreateInvite({
        setHeaderText,
        setHeaderSubText,
        squadName,
        selectedSquad,
    });

    /**
     * End Hooks
     */

    function renderBody() {
        return (
            <div className={styles.body} data-cy="invite-to-squad-body">
                <div className={styles.inputs}>{renderInputs()}</div>
                <div
                    className={styles.addInput}
                    data-cy="invite-to-squad-add-line-button"
                    onKeyDown={(e) => handleKeyDown(e, handleAddEmail)}
                    onClick={handleAddEmail}
                    tabIndex="0"
                >
                    <AddIcon />
                    <Text>{t("add_new_line", { ns: "common" })}</Text>
                </div>
            </div>
        );
    }

    function renderButtons() {
        return (
            <div className={styles.buttons}>
                <ButtonPrimary
                    cypressTestId="invite-to-squad-submit-button"
                    isDisabled={!hasEnteredAnEmail}
                    isLoading={isSendingEmails}
                    onClick={handleSubmitEmailInvites}
                    type="submit"
                >
                    <Text>{t("send_invites", { ns: "common" })}</Text>
                </ButtonPrimary>
                <ButtonInvisible
                    cypressTestId="invite-to-squad-cancel-button"
                    onClick={handleModalClose}
                    type="reset"
                >
                    <Text>{t("cancel", { ns: "common" })}</Text>
                </ButtonInvisible>
            </div>
        );
    }

    function renderInputs() {
        return [...Array(numberOfEmails).keys()].map((i) => {
            const label = `${t("email_address", { ns: "common" })} #${i + 1}`;
            return (
                <TextFieldValidated
                    autoComplete="nofill"
                    className={styles.input}
                    cypressTestId={`support-squad-email-input-${i}`}
                    id={`supportSquadEmail-${i}`}
                    isValid={Validation.isEmailAddress(inviteEmails[i])}
                    key={i}
                    label={label}
                    name={`supportSquadEmail-${i}`}
                    onChange={(e) => handleEmailChange(i, e)}
                    size="small"
                    type="email"
                    value={inviteEmails[i]}
                />
            );
        });
    }

    return (
        <div className={styles.supportSquadSettingsModalCreateInvite}>
            <form className={styles.form}>
                {renderBody()}
                {renderButtons()}
            </form>
        </div>
    );
};

SupportSquadSettingsModalCreateInvite.propTypes = {
    handleAddEmail: PropTypes.func.isRequired,
    handleEmailChange: PropTypes.func.isRequired,
    handleModalClose: PropTypes.func.isRequired,
    handleSubmitEmailInvites: PropTypes.func.isRequired,
    hasEnteredAnEmail: PropTypes.bool.isRequired,
    inviteEmails: PropTypes.array.isRequired,
    isSendingEmails: PropTypes.bool.isRequired,
    numberOfEmails: PropTypes.number.isRequired,
    setHeaderSubText: PropTypes.func.isRequired,
    setHeaderText: PropTypes.func.isRequired,
    squadName: PropTypes.string,
    selectedSquad: PropTypes.object.isRequired,
};
