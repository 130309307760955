import { useContext, useEffect, useRef, useState } from "react";

// Contexts
import { IterableContext } from "context/iterable/iterable";

// Utils
import { DEV } from "utils/urlUtils";

// Enums
import { IterableEmbeddedMessagesPlacementId } from "enums/iterable/IterableEmbeddedMessagePlacementId";

// Utils
import {
    addAlertToDismissedMessageIdsList,
    checkIfAlertWasDismissed,
} from "scenes/Dashboard/components/DashboardAlerts/utils";

// Constants
const embeddedMessagesPlacementId = DEV
    ? IterableEmbeddedMessagesPlacementId.DASHBOARD_ALERTS_STAGE
    : IterableEmbeddedMessagesPlacementId.DASHBOARD_ALERTS_PROD;

export const useDashboardAlerts = () => {
    /**
     * useRef
     */

    const containerRef = useRef(null);

    /**
     * useContext
     */

    const { embeddedMessages } = useContext(IterableContext);

    /**
     * useState
     */

    const [alerts, setAlerts] = useState(null);
    const [containerHeight, setContainerHeight] = useState("auto");
    const [hasRenderedAlerts, setHasRenderedAlerts] = useState(false);
    const [hasRenderedCarousel, setHasRenderedCarousel] = useState(false);

    /**
     * useEffect
     */

    useEffect(() => {
        if (alerts?.length > 0 && containerRef.current) {
            setContainerHeight(`${containerRef.current.scrollHeight}px`);
            setHasRenderedAlerts(true);
        } else if (hasRenderedAlerts && alerts?.length === 0) {
            setContainerHeight("0px");
        }

        if (alerts?.length >= 2) {
            setHasRenderedCarousel(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [alerts]);

    useEffect(() => {
        if (!embeddedMessages) {
            setAlerts([]);
        } else {
            setAlerts(
                embeddedMessages[embeddedMessagesPlacementId].filter(
                    (alert) => !checkIfAlertWasDismissed(alert)
                )
            );
        }
    }, [embeddedMessages]);

    /**
     * End Hooks
     */

    function handleCloseAlert(alertToClose) {
        setAlerts((prev) =>
            prev.filter(
                (alert) =>
                    alert.metadata.messageId !== alertToClose.metadata.messageId
            )
        );
        addAlertToDismissedMessageIdsList(alertToClose);
    }

    return {
        alerts,
        containerHeight,
        containerRef,
        handleCloseAlert,
        hasRenderedCarousel,
    };
};
