import React from "react";

// Components
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import CardGiftcardOutlinedIcon from "@mui/icons-material/CardGiftcardOutlined";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import { Text } from "components/Text/Text";

// CSS
import styles from "./support-squad-list-empty.module.scss";

// Localization
import { useTranslation } from "localization/localization";

// Media
import supportSquadHeaderSrc from "media/scenes/support-squad/support-squad-header.jpg";

export const SupportSquadListEmpty = () => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation("support-squad");

    /**
     * End Hooks
     */

    return (
        <div className={styles.supportSquadListEmpty}>
            <div
                className={styles.header}
                style={{ backgroundImage: `url(${supportSquadHeaderSrc})` }}
            >
                <Text classes={styles.headline} isBold>
                    {t("list.create_community_title", { ns: "support_squad" })}
                </Text>
                <Text classes={styles.headline} isBold>
                    {t("list.create_community_subtitle", {
                        ns: "support_squad",
                    })}
                </Text>
            </div>
            <div className={styles.list}>
                <div className={styles.item}>
                    <div className={styles.icon}>
                        <MailOutlineIcon fontSize="large" />
                    </div>
                    <div className={styles.body}>
                        <Text classes={styles.title} isBold>
                            {t("list.share_letters_title", {
                                ns: "support_squad",
                            })}
                        </Text>
                        <Text classes={styles.subtitle}>
                            {t("list.share_letters_subtitle", {
                                ns: "support_squad",
                            })}
                        </Text>
                    </div>
                </div>
                <div className={styles.item}>
                    <div className={styles.icon}>
                        <PeopleOutlineIcon fontSize="large" />
                    </div>
                    <div className={styles.body}>
                        <Text classes={styles.title} isBold>
                            {t("list.watch_support_title", {
                                ns: "support_squad",
                            })}
                        </Text>
                        <Text classes={styles.subtitle}>
                            {t("list.watch_support_subtitle", {
                                ns: "support_squad",
                            })}
                        </Text>
                    </div>
                </div>
                <div className={styles.item}>
                    <div className={styles.icon}>
                        <CalendarMonthOutlinedIcon fontSize="large" />
                    </div>
                    <div className={styles.body}>
                        <Text classes={styles.title} isBold>
                            {t("list.weekly_updates_title", {
                                ns: "support_squad",
                            })}
                        </Text>
                        <Text classes={styles.subtitle}>
                            {t("list.weekly_updates_subtitle", {
                                ns: "support_squad",
                            })}
                        </Text>
                    </div>
                </div>
                <div className={styles.item}>
                    <div className={styles.icon}>
                        <CardGiftcardOutlinedIcon fontSize="large" />
                    </div>
                    <div className={styles.body}>
                        <Text classes={styles.title} isBold>
                            {t("list.basic_needs_title", {
                                ns: "support_squad",
                            })}
                        </Text>
                        <Text classes={styles.subtitle}>
                            {t("list.basic_needs_subtitle", {
                                ns: "support_squad",
                            })}
                        </Text>
                    </div>
                </div>
            </div>
        </div>
    );
};
