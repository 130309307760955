import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";

// Component
import { Skeleton } from "@mui/material";
import { Text } from "components/Text/Text";

// CSS
import styles from "./support-squad-detail-header-metrics.module.scss";

// Hooks
import { useMeasure } from "@uidotdev/usehooks";

// Localization
import { useTranslation } from "localization/localization";

// Utils
import { handleKeyDown } from "utils/eventUtils";

export const SupportSquadDetailHeaderMetrics = ({
    handleOpenMembersList,
    isFetchingSquad,
    selectedSquad,
}) => {
    /**
     * Custom Hooks
     */

    const [metricRef, { height }] = useMeasure();
    const { t } = useTranslation("support-squad");

    /**
     * End Hooks
     */

    function renderValue(value) {
        return isFetchingSquad ? (
            <Skeleton className={styles.skeleton} />
        ) : (
            <Text classes={styles.value}>{value}</Text>
        );
    }

    return (
        <div className={styles.supportSquadDetailHeaderMetrics}>
            <div
                className={cx(styles.metric, styles["metric--action"])}
                ref={metricRef}
                onClick={handleOpenMembersList}
                onKeyDown={(e) => {
                    handleKeyDown(e, handleOpenMembersList);
                }}
                style={{ width: height }}
                tabIndex="0"
            >
                {renderValue(selectedSquad?.memberCount)}
                <div className={styles.label}>
                    <Text>{t("squad_singular", { ns: "support_squad" })}</Text>
                    <Text>{t("member_plural", { ns: "common" })}</Text>
                </div>
            </div>
            <div className={styles.metric} style={{ width: height }}>
                {renderValue(selectedSquad?.letterCount)}
                <div className={styles.label}>
                    <Text>{t("letter_ip_plural", { ns: "common" })}</Text>
                    <Text>{t("sent", { ns: "common" })}</Text>
                </div>
            </div>
            <div className={styles.metric} style={{ width: height }}>
                {renderValue(selectedSquad?.tokenCount)}
                <div className={styles.label}>
                    <Text>{t("letter_ip_plural", { ns: "common" })}</Text>
                    <Text>{t("remaining", { ns: "common" })}</Text>
                </div>
            </div>
        </div>
    );
};

SupportSquadDetailHeaderMetrics.propTypes = {
    handleOpenMembersList: PropTypes.func.isRequired,
    selectedSquad: PropTypes.object,
};
