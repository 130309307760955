import { useState } from "react";

export const useAlert = ({ onClose }) => {
    const [isVisible, setIsVisible] = useState(true);

    function handleClose() {
        setIsVisible(false);
        setTimeout(() => {
            onClose();
        }, 300);
    }

    return { handleClose, isVisible };
};
