import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// Components
import { ButtonInvisible, ButtonPrimary } from "components/Buttons/Buttons";
import { Checkbox } from "components/Inputs/Checkbox/Checkbox";
import { Text } from "components/Text/Text";
import { WarningCircleIcon } from "components/Icons/WarningCircleIcon";

// CSS
import styles from "./support-squad-delete-modal.module.scss";

// Localization
import { useTranslation } from "localization/localization";

export const SupportSquadDeleteModalDeleteSquad = (props) => {
    const {
        deleteRequestPending,
        deleteSquad,
        handleChangeCheckbox,
        handleModalClose,
        hasAcceptedDeleteTerms,
        setHasAcceptedDeleteTerms,
        selectedSquad,
    } = props;

    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * End Hooks
     */

    function renderContent() {
        return (
            <div className={styles.container}>
                <WarningCircleIcon height="64" width="64" />
                <span className={styles.headerTextRed}>
                    {t("settings.delete_title", {
                        ns: "support_squad",
                    })}
                </span>
                <span className={styles.bodyText}>
                    {t("settings.delete_subtitle", {
                        ns: "support_squad",
                    })}
                </span>
                <div className={styles.itemsToBeDeleted}>
                    <span className={styles.item}>
                        {t("sent_letter", {
                            count: selectedSquad.letterCount,
                            ns: "common",
                        })}
                    </span>
                    <span className={styles.item}>
                        {t("token", {
                            count: selectedSquad.tokenCount,
                            ns: "common",
                        })}
                    </span>
                    <span className={styles.item}>
                        {t("settings.delete_support_squad_access", {
                            ns: "support_squad",
                        })}
                    </span>
                </div>
                <div className={styles.checkbox}>
                    <Checkbox
                        cyId="support-squad-delete-squad-confirm-checkbox"
                        checked={hasAcceptedDeleteTerms}
                        id="support-squad-delete-squad-confirm-checkbox"
                        onChange={handleChangeCheckbox}
                        text={t("user_agree_above_terms", {
                            ns: "common",
                        })}
                    />
                </div>
            </div>
        );
    }

    function renderButtons() {
        return (
            <>
                <ButtonPrimary
                    classes={cx(
                        styles["remove-button"],
                        "button--primary-warning"
                    )}
                    cypressTestId="delete-squad-confirm-button"
                    isDisabled={!hasAcceptedDeleteTerms || deleteRequestPending}
                    isLoading={deleteRequestPending}
                    onClick={() =>
                        deleteSquad(() => setHasAcceptedDeleteTerms(false))
                    }
                >
                    <Text>
                        {t("settings.delete_squad", { ns: "support_squad" })}
                    </Text>
                </ButtonPrimary>
                <ButtonInvisible
                    classes={styles.button}
                    onClick={handleModalClose}
                >
                    <Text>{t("cancel", { ns: "common" })}</Text>
                </ButtonInvisible>
            </>
        );
    }

    return (
        <div className={styles.removeMembersWarning}>
            <div className="modal__content">{renderContent()}</div>
            <div className={styles.buttonContainer}>{renderButtons()}</div>
        </div>
    );
};

SupportSquadDeleteModalDeleteSquad.propTypes = {
    deleteRequestPending: PropTypes.bool.isRequired,
    deleteSquad: PropTypes.func.isRequired,
    handleChangeCheckbox: PropTypes.func.isRequired,
    handleModalClose: PropTypes.func.isRequired,
    hasAcceptedDeleteTerms: PropTypes.bool.isRequired,
    setHasAcceptedDeleteTerms: PropTypes.func.isRequired,
    selectedSquad: PropTypes.object.isRequired,
};
