import React, { useEffect } from "react";
import PropTypes from "prop-types";

// Components
import { ButtonInvisible, ButtonPrimary } from "components/Buttons/Buttons";
import { Checkbox } from "components/Inputs/Checkbox/Checkbox";
import { Text } from "components/Text/Text";

// CSS
import styles from "./support-squad-donate-tokens-modal-confirm.module.scss";

// Localization
import { useTranslation } from "localization/localization";

// Constants
import { NavigationEnum } from "../../constants/constants";
const { ENTER_AMOUNT } = NavigationEnum;

export const SupportSquadDonateTokensModalConfirm = (props) => {
    const {
        handleDonateTokens,
        hasAgreedToDonationTerms,
        loading,
        setCurrentSection,
        setHasAgreedToDonationTerms,
        setHeaderText,
        setHeaderSubText,
    } = props;

    /**
     * Custom Hooks
     */

    const { t } = useTranslation("support-squad");

    /**
     * useEffect
     */

    useEffect(() => {
        setHeaderText(t("donate.confirm_title", { ns: "support_squad" }));
        setHeaderSubText("");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * End Hooks
     */

    function handleChangeCheckbox() {
        setHasAgreedToDonationTerms((prev) => !prev);
    }

    function handleGoBack() {
        setCurrentSection(ENTER_AMOUNT);
    }

    return (
        <div
            className={styles["support-squad-donate-tokens-modal-confirm"]}
            data-cy="donate-tokens-confirm-modal"
        >
            <div className={styles.text}>
                <p>{t("donate.confirm_subtitle", { ns: "support_squad" })}</p>
            </div>
            <div className={styles.checkbox}>
                <Checkbox
                    cyId="support-squad-donate-tokens-modal-confirm-checkbox"
                    checked={hasAgreedToDonationTerms}
                    id="support-squad-donate-tokens-modal-confirm-checkbox"
                    onChange={handleChangeCheckbox}
                    text={t("donate.user_agree_terms", { ns: "support_squad" })}
                />
            </div>
            <div className={styles.buttons}>
                <ButtonPrimary
                    classes={styles.button}
                    cypressTestId="donate-tokens-confirm-continue-button"
                    isDisabled={!hasAgreedToDonationTerms}
                    isLoading={loading}
                    onClick={handleDonateTokens}
                >
                    <Text>{t("continue", { ns: "common" })}</Text>
                </ButtonPrimary>
                <ButtonInvisible
                    classes={styles.button}
                    cypressTestId="donate-tokens-confirm-back-button"
                    onClick={handleGoBack}
                >
                    <Text>{t("back", { ns: "common" })}</Text>
                </ButtonInvisible>
            </div>
        </div>
    );
};

SupportSquadDonateTokensModalConfirm.propTypes = {
    handleDonateTokens: PropTypes.func.isRequired,
    hasAgreedToDonationTerms: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    setCurrentSection: PropTypes.func.isRequired,
    setHasAgreedToDonationTerms: PropTypes.func.isRequired,
    setHeaderText: PropTypes.func.isRequired,
    setHeaderSubText: PropTypes.func.isRequired,
};
