import React from "react";
import PropTypes from "prop-types";

// Components
import { ButtonPrimary, ButtonSecondary } from "components/Buttons/Buttons";
import { GraduationProgressBar } from "components/GraduationProgressBar/GraduationProgressBar";
import { Panel } from "components/Panel/Panel";
import { SupportSquadDetailHeaderControls } from "./components/SupportSquadDetailMetrics/SupportSquadDetailHeaderControls/SupportSquadDetailHeaderControls";
import { SupportSquadDetailHeaderMetrics } from "./components/SupportSquadDetailMetrics/SupportSquadDetailHeaderMetrics";
import { SupportSquadDetailHeaderTitle } from "./components/SupportSquadDetailHeaderTitle/SupportSquadDetailHeaderTitle";
import { Text } from "components/Text/Text";

// CSS
import styles from "./support-squad-detail-header.module.scss";

// Localization
import { useTranslation } from "localization/localization";

export const SupportSquadDetailHeader = ({
    handleClickSendLetter,
    handleOpenAdminSettings,
    handleOpenDonateTokenModal,
    handleOpenMembersList,
    isFetchingSquad,
    setCurrentSection,
    setShouldShowCreateModal,
    setShouldShowLeaveModal,
    selectedSquad,
}) => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * End Hooks
     */

    return (
        <Panel classes={styles.supportSquadDetailHeader}>
            <div className={styles.header}>
                <SupportSquadDetailHeaderTitle
                    isFetchingSquad={isFetchingSquad}
                    selectedSquad={selectedSquad}
                />
                <SupportSquadDetailHeaderControls
                    handleOpenAdminSettings={handleOpenAdminSettings}
                    isFetchingSquad={isFetchingSquad}
                    setCurrentSection={setCurrentSection}
                    setShouldShowCreateModal={setShouldShowCreateModal}
                    setShouldShowLeaveModal={setShouldShowLeaveModal}
                    selectedSquad={selectedSquad}
                />
            </div>
            <div className={styles.body}>
                <SupportSquadDetailHeaderMetrics
                    handleOpenMembersList={handleOpenMembersList}
                    isFetchingSquad={isFetchingSquad}
                    selectedSquad={selectedSquad}
                />
                <div className={styles.buttons}>
                    <ButtonPrimary
                        classes={styles.button}
                        cypressTestId="send-letter-button"
                        isDisabled={isFetchingSquad}
                        onClick={handleClickSendLetter}
                    >
                        <Text>{t("send_letter", { ns: "common" })}</Text>
                    </ButtonPrimary>
                    <ButtonSecondary
                        classes={styles.button}
                        cypressTestId="donate-tokens-button"
                        isDisabled={isFetchingSquad}
                        onClick={handleOpenDonateTokenModal}
                    >
                        <Text>
                            {t("donate_letters", { ns: "support_squad" })}
                        </Text>
                    </ButtonSecondary>
                </div>
            </div>
            {selectedSquad?.graduation?.currentWeeksUntil ? (
                <GraduationProgressBar
                    className={styles.graduationProgressBar}
                    weeksToGraduation={
                        selectedSquad?.graduation.currentWeeksUntil
                    }
                    totalWeeks={selectedSquad?.graduation.maxNumberOfWeeks}
                />
            ) : null}
        </Panel>
    );
};

SupportSquadDetailHeader.propTypes = {
    handleClickSendLetter: PropTypes.func.isRequired,
    handleOpenAdminSettings: PropTypes.func.isRequired,
    handleOpenDonateTokenModal: PropTypes.func.isRequired,
    handleOpenMembersList: PropTypes.func.isRequired,
    isFetchingSquad: PropTypes.bool,
    setCurrentSection: PropTypes.func.isRequired,
    setShouldShowCreateModal: PropTypes.func.isRequired,
    setShouldShowLeaveModal: PropTypes.func.isRequired,
    selectedSquad: PropTypes.object,
};
