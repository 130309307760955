import React, { createContext, useState } from "react";

// Platforms
import { Iterable } from "platforms/iterable/iterable";

export const IterableContext = createContext({});

export const IterableProvider = ({ children }) => {
    /**
     * useState
     */

    const [embeddedMessages, setEmbeddedMessages] = useState(null);
    const [isLoadingEmbeddedMessages, setIsLoadingEmbeddedMessages] =
        useState(false);

    /**
     * End Hooks
     */

    async function fetchEmbeddedMessages() {
        setIsLoadingEmbeddedMessages(() => true);
        const messages = await Iterable.getEmbeddedMessages();
        setEmbeddedMessages(messages);
        setIsLoadingEmbeddedMessages(false);
    }

    return (
        <IterableContext.Provider
            value={{
                embeddedMessages,
                fetchEmbeddedMessages,
                isLoadingEmbeddedMessages,
                setEmbeddedMessages,
            }}
        >
            {children}
        </IterableContext.Provider>
    );
};
